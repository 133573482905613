import React from 'react'
import "../scss/DealerCard.scss"

function DealerCard(props) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const specials = ['Manager', 'Finance', 'Lease']

    const mapHours = (hoursArray) => {
        return days.map((day, i) => hoursArray[i] && (
            <div key={day} className="card-hours">
                <div className="card-day">{day}: </div>{hoursArray[i].time}
            </div>
        ))
    }

    const renderHours = (title, hoursArray) => {
        return hoursArray.length > 0 && (
            <div className="view-hoursBox">
                <div className="card-sales">{title}:</div>
                <div className="view-hours">
                    {mapHours(hoursArray)}
                </div>
            </div>
        )
    }

    let showDealerCard = false
    let dealers = props.group.dealers.map((dealer, i) => {
        let buttons = specials.map(special => {
            if (dealer[special.toLowerCase() + 'SpecialPage'] !== '') {
                showDealerCard = true
                return (
                    <a key={special} href={dealer[special.toLowerCase() + 'SpecialPage']} className='card-button'>{special} Specials</a>
                )
            }
            return (<div></div>)
        })
        
        const singleButton = () => ( <
                a href = { dealer.sellYourCarLink }
                  className = 'card-button' > { props.single } <
                    /a>
        )
        if(showDealerCard){
            return (
                <div key={i} className="card-dealer">
                    <img alt={dealer.dealerName + " Dealer Card"} src={dealer.specImage} className="card-image" />
                    <div className="card-name" style={{ color: props.theme.colors.accent1 }}>{dealer.dealerName}</div>
                    <div className="card-address"><strong>Location:</strong> {dealer.dealerAddress} | <a href={dealer.directions} className="card-directions">Get Directions</a></div>
                    <div className='card-buttonCont'>
                        {props.single ? singleButton() : buttons}
                    </div>
                    {renderHours("Sales Hours", dealer.salesHours)}
                </div>
            )
        }else{
            return (<div></div>)
        }
    })

    return (
        <div className="card-Cont">
            {dealers}
        </div>
    )
}

export default DealerCard
