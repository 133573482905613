import React from "react"
import Layout from '../components/layout'
import OurSpecials from '../components/OurSpecials'
import DealerCard from '../components/DealerCard'
import SEO from '../components/SEO'
import HTML from '../components/HTML'
import '../scss/Specials.scss'

function Landing(props) {
    const { pageContext } = props
    const { pageContent, theme, dealerInfo } = pageContext

    let components = [
        <OurSpecials key={"OurSpecials"} topTitle={""} group={dealerInfo} data={pageContent.SellYourCar} theme={theme} />,
        <SEO key={"SEO"} title={"Specials"} data={pageContent.SEO} />
    ]

    let sortedComponents = components.sort((a, b) => parseFloat(a.props.data.order) - parseFloat(b.props.data.order)) // sorts the components based on json data

    let visibleComponents = sortedComponents.filter(component => { // filters out the components that are not visible
        return component.props.data.visible === true
    })

    return (
        <Layout>
            <HTML key={"HTML"} data={pageContent.HTML} />
            <img className="landing-banner" src={pageContent.SellYourCar.topBanner} />
            <DealerCard single={"Sell your car here"} group={dealerInfo} theme={theme} />
            {visibleComponents}
        </Layout>
    )
}

export default Landing

